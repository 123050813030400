<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
        <ValidationObserver ref="datos_busqueda">
          <div class="md:flex md:justify-between">
              <div class="w-full pr-1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel"
                    :rules=fields_oferta_academica.group_datos_basicos.json_nivel.caracteristicas.required
                  >
                      <v-select
                        v-model="fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel"
                        :items="list_niveles"
                        item-text="tx_nivel"
                        item-value="nu_id_nivel_educativo"
                        class="input-group--focused text-lg"
                        :placeholder=fields_oferta_academica.group_datos_basicos.json_nivel.caracteristicas.placeholder
                        :error-messages="errors"
                        :label=fields_oferta_academica.group_datos_basicos.json_nivel.caracteristicas.label
                        outlined
                        return-object
                        @change="setGrados"
                      ></v-select>                      

                  </validation-provider>     
              </div>        
              <div class="w-full pr-1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="fields_oferta_academica.group_datos_basicos.json_grados.json_grados"
                    :rules=fields_oferta_academica.group_datos_basicos.json_grados.caracteristicas.required
                  >
                      <v-select
                        v-model='fields_oferta_academica.group_datos_basicos.json_grados.json_grados'
                        :items="list_institucion_nivel_grados"
                        item-text="tx_nombre_grado"
                        item-value="nu_id_grado"
                        class="input-group--focused text-lg"
                        :placeholder=fields_oferta_academica.group_datos_basicos.json_grados.caracteristicas.placeholder
                        :error-messages="errors"
                        :label=fields_oferta_academica.group_datos_basicos.json_grados.caracteristicas.label
                        outlined
                        return-object
                        @change="setAreas"
                      ></v-select>                      


                  </validation-provider>     
              </div>  
              <div class="w-full pr-1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="fields_oferta_academica.group_datos_basicos.json_areas.json_areas"
                    :rules=fields_oferta_academica.group_datos_basicos.json_areas.caracteristicas.required
                  >
                      <v-select
                        v-model='fields_oferta_academica.group_datos_basicos.json_areas.json_areas'
                        :items="list_institucion_nivel_grado_areas"
                        item-text="tx_descripcion"
                        item-value="nu_id_area_formacion"
                        class="input-group--focused text-lg"
                        :placeholder=fields_oferta_academica.group_datos_basicos.json_areas.caracteristicas.placeholder
                        :error-messages="errors"
                        :label=fields_oferta_academica.group_datos_basicos.json_areas.caracteristicas.label
                        outlined
                        return-object
                        @change="setConfigurar"
                      ></v-select>                      

                  </validation-provider>     
              </div>  

          </div>  

        </ValidationObserver>

<!-- <div class="flex flex-col w-full">
        <div class="" v-for="(item, index) in list_plan_evaluacion" :key="index">
          <div class="" v-for="(item_01, index_1) in item.criterio_evaluacions" :key="index_1">
              <div class="w-1/2 pb-2 pt-2">
                  <div class="flex justify-between"> 
                    <div class="font-bold"> 

                      {{ item_01.tx_denominacion_criterio_evaluacion }}
                    </div>
                    <div class="font-bold"> 
                      {{ item_01.nu_ponderacion_criterio }}%
                    </div>
                  </div>
                </div>



              <div class="w-1/2" v-for="(item_02, index_2) in item_01.criterio_indicadores_aprendizajes" :key="index_2">
                  <div class="flex justify-between pl-10"> 
                    <div class="..."> 
                      {{ item_02.tx_denominacion_indicador_aprendizaje }}
                    </div>
                    <div class="..."> 
                      {{ item_02.nu_ponderacion_indicador_aprendizaje }}%
                    </div>
                  </div>

               </div>                        
          </div>                        
        </div>                        

</div> -->


        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="list_criterios"
                    :items-per-page="5"
                    :search="search"      
                    :expanded.sync="expanded" 
                    :single-expand="singleExpand" 
                    show-expand                                     
                    class="elevation-1"
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="indicadores_logro(item)"
                        >
                          {{ icons.mdiBookPlusMultiple }}
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          @click="agregar_actividad(item)"
                        >
                          {{ icons.mdiAlarmPlus }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <header class="grid grid-cols-3 gap-4 bg-gray-300">
                            <!-- Header content -->
                              <div class="...">Indicador</div>
                              <div class="...">Ponderación</div>
                          </header>                          
                            <div class="grid grid-cols-3 gap-4" v-for="(item_01, index) in item.criterio_indicadores_aprendizajes" :key="index">
                              <div class="..."> {{ item_01.tx_denominacion_indicador_aprendizaje }}</div>
                              <div class="..."> {{ item_01.nu_ponderacion_indicador_aprendizaje }}</div>
                            </div>                        
                        </td>
                      </template>
                        <!-- name -->
                      <!--  <template #[`item.json_competencia_indicadores`]="{item}">
                          <div class="d-flex flex-column">
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_competencia_indicadores)" :key="index">   
                                   
                                <span class="text-xs"> {{ item_01.co_indicador }} </span>
                                <span class="text-xs"> [{{ item_01.tx_nombre_indicador }} ] </span>
                              </div>
                          <small>{{ item.tx_nombres }}</small>
                          </div>
                        </template>          
                        -->
                  </v-data-table>
              </div>
    </div>

        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogDefinirUnidades" :value="true" @input="dialogDefinirUnidades = false"                   
            >
            <v-card>
              <v-container>
                <div class="w-full mx-auto my-auto">

                    <Actividades :titulo='titulo' :sub_titulo='sub_titulo' @guardar_definir_unidad="return_definir_unidad" :send_datos="send_datos"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { LIST_INSTITUCION, LIST_PLAN_EVALUACION, LIST_PLANES,LIST_INSTITUCION_NIVELES,LIST_INSTITUCION_NIVEL_PLAN_GRADOS,LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREAS, LIST_OFERTA_ACADEMICA, CREATE_ASOCIAR_INSTITUCION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import Actividades from "@/views/configuracion/Academica/Actividad/Actividad";

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiNotebookOutline,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiMagnify
} from '@mdi/js'


export default {
  name: 'EvaluacionComp',
  data () {
    return {
          send_datos: {},
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
        titulo: '',
        search: '',
        list_plan_evaluacion: [],
        list_criterios: [],
        list_instituciones: [],
        list_niveles: [],
        list_planes: [],
        list_plan_institucion: [],
        list_institucion_nivel_grados: [],
        list_institucion_nivel_grado_areas: [],
        fields_oferta_academica_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        dialogDefinirUnidades: false,
        dialogAsociarUnidad: false,
        editedIndex: -1,
        editedItem: {
          json_institucion: '',
          json_especialidad: '',
        },  
        defaultItem: {
          json_institucion: [],
          json_especialidad: [],
          caracteristicas: {
            nombre: 'json_institucion',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        institucion_especialidad: {},
        items_data_table: [],   
        items_niveles: {
          json_ano_institucion: [],
          tx_nivel: '',
          tx_etiqueta: '',
          tx_descripcion: '',
          nu_id_padre: '',
          json_institucion: [],
        },   
          expanded: [],
          singleExpand: false,
            headers: [
              {
                text: 'Criterio de evaluación',
                align: 'start',
                sortable: false,
                value: 'tx_denominacion_criterio_evaluacion',
              },          
              {
                text: 'Ponderación',
                sortable: false,
                value: 'nu_ponderacion_criterio',
              },          
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },          
              { text: '', value: 'data-table-expand' },              
            ],            

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
        mdiNotebookOutline,
        mdiMagnify
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_oferta_academica','currentUser']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },
  },
  props: {
  },
  components: {
    NotificacionInterna,
    Actividades,
  },    
  methods: {    
    agregar_actividad(item) {    
        //this.$emit("actualizar_datos_usuario", msg);
        this.titulo = 'Definir actividad'
        this.sub_titulo = this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel.tx_nivel + ' / ' + this.fields_oferta_academica.group_datos_basicos.json_grados.json_grados.tx_nombre_grado + ' / ' + this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.tx_descripcion

        this.send_datos.criterio = item

        this.dialogDefinirUnidades = true
    },    
    asociar_unidades() {    
        //this.$emit("actualizar_datos_usuario", msg);
        this.titulo = 'Asociar  unidades'
        this.sub_titulo = this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel.tx_nivel + ' / ' + this.fields_oferta_academica.group_datos_basicos.json_grados.json_grados.tx_nombre_grado + ' / ' + this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.tx_descripcion

        this.send_datos.datos_academicos = {
            nu_id_ano_escolar: this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.nu_id_ano_escolar,
            nu_id_institucion: this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.nu_id_institucion,
            nu_id_nivel_educativo: this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.nu_id_nivel_educativo,
            nu_id_plan: this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.nu_id_plan,
            nu_id_grado: this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.nu_id_grado,
            nu_id_area_formacion: this.fields_oferta_academica.group_datos_basicos.json_areas.json_areas.nu_id_area_formacion,
        }

        this.dialogAsociarUnidad = true
    },    
    return_definir_unidad() {    
        //this.$emit("actualizar_datos_usuario", msg);
        this.dialogDefinirUnidades = false
    },    
    return_asociar_unidad() {    
        //this.$emit("actualizar_datos_usuario", msg);
        this.dialogAsociarUnidad = false
    },    
      setPlanes(value) {
        //  trigger a mutation, or dispatch an action  
        this.searchPlanes(value.nu_id_ano_escolar)
      },    
      setGrados(value) {
        //  trigger a mutation, or dispatch an action 
         
        this.searchGrados(value)
      },    
      setAreas(value) {
        //  trigger a mutation, or dispatch an action 
         
        this.searchAreas(value)
      },    
      setConfigurar(value) {
        //  trigger a mutation, or dispatch an action 
         
        this.searchPlanEvaluacion(value)
      },    
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.cleanig()
          //this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion = []
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          //this.institucion_especialidad = Object.assign({}, this.fields_oferta_academica.group_datos_basicos.json_institucion.json_institucion)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {
          console.log('this.editedItem: ', this.fields_oferta_academica.group_datos_basicos.tx_nombre_ano.tx_nombre_ano)
          /*var aa = {
            json_institucion: this.institucion_especialidad.json_institucion,
            json_especialidad: this.institucion_especialidad.json_especialidad
          }
          */
          //this.fields_oferta_academica.json_institucion.json_especialidad = this.institucion_especialidad.json_especialidad
        for (const property in this.fields_oferta_academica.group_datos_basicos.json_grados.json_grados) {
          console.log(property, ' ', this.fields_oferta_academica.group_datos_basicos.json_grados.json_grados[property])
        }
          this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion.push({
            tx_nombre_ano: this.fields_oferta_academica.group_datos_basicos.tx_nombre_ano.tx_nombre_ano,
            json_institucion: this.fields_oferta_academica.group_datos_basicos.json_institucion.json_institucion,
            json_nivel: this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel,
            json_plan_estudio: this.fields_oferta_academica.group_datos_basicos.json_plan_estudio.json_plan_estudio,
            json_grados: this.fields_oferta_academica.group_datos_basicos.json_grados.json_grados,
            tx_nombre_grado: this.fields_oferta_academica.group_datos_basicos.json_grados.json_grados.tx_nombre_grado,
            nu_id_ano_escolar: this.send_datos.AnoEscolar.nu_id_ano_escolar,
            nu_id_institucion: this.fields_oferta_academica.group_datos_basicos.json_institucion.json_institucion.nu_id_institucion,
            nu_id_nivel_educativo: this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel.nu_id_nivel_educativo,
            nu_id_plan: this.fields_oferta_academica.group_datos_basicos.json_plan_estudio.json_plan_estudio.nu_id_plan,
          })


            //this.buscarPersona('params')

            const formData = new FormData()
            formData.append('ano_escolar_institucion', JSON.stringify(this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_ASOCIAR_INSTITUCION, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "La institución " + JSON.parse(datos.datos_ano_escolar_institucion).tx_razon_social + " fue asociada satisfactoriamente"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_academico));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creados satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("actualizar_datos_academico", this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion);              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             

              })  

          /// hasta aca optimizar


          //this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion = this.items_data_table
          console.log('this.editedItem items_data_table: ', this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion)
          //this.fields_oferta_academica.group_datos_basicos.json_institucion.json_institucion.push(this.institucion_especialidad.json_institucion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        //this.searchInstitucion(item.json_institucion.nu_id_institucion)
        this.editedIndex = this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion.indexOf(item)
        const claves_primarias = {}
        claves_primarias.tx_nombre_ano = 'tx_nombre_ano'
        for (const property in item) {
            //console.log('item: ', property)
            if (claves_primarias[property] != property) {
              this.fields_oferta_academica.group_datos_basicos[property][property] = item[property]
            }               
        }

        this.dialog = true
      },
      areaFormacion (item) {
        this.editedItem = Object.assign({}, item)
        this.send_datos.area_formacion = item
        console.log('editedItem: ', item)
        this.dialogDefinirUnidades = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_ano_institucion = 'json_ano_institucion'

        for (const property in this.fields_oferta_academica.group_datos_basicos) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_oferta_academica.group_datos_basicos[property][property] = []
              } else {
                this.fields_oferta_academica.group_datos_basicos[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        console.log('deleteItem: ', item)
        this.editedIndex = this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchEspecialidad() {
          this.list_plan_evaluacion = JSON.parse(this.institucion_especialidad.json_institucion.json_especialidad)
    },    
    searchInstituciones(q) {
      this.$store.dispatch(LIST_INSTITUCION, {q: q}).then(response => {
          this.list_instituciones = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchNiveles(params) {
      this.$store.dispatch(LIST_INSTITUCION_NIVELES, params).then(response => {
          this.list_niveles = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchPlanes(q) {
      var params = {
        nu_id_ano_escolar: q,
        nu_id_nivel_educativo: this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel.nu_id_nivel_educativo
      }
      console.log('params: ', params)
      this.$store.dispatch(LIST_PLANES, params ).then(response => {
          this.list_plan_institucion = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchGrados(params) {
      console.log('Año escolar: ', params)
      //var params = {
      //  nu_id_ano_escolar: q.nu_id_ano_escolar,
      //  nu_id_nivel_educativo: this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel.nu_id_nivel_educativo
      //}
      console.log('params: ', params)
      this.$store.dispatch(LIST_INSTITUCION_NIVEL_PLAN_GRADOS, params ).then(response => {
          this.list_institucion_nivel_grados = response.rest.items            
          this.list_institucion_nivel_grados.push({nu_id_nivel_educativo: '99999', tx_nivel: 'Todos',nu_id_grado: 99999,
            tx_nombre_grado: 'Todos'})

      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchPlanEvaluacion(params) {
      console.log('Año escolar: ', params)
      //var params = {
      //  nu_id_ano_escolar: q.nu_id_ano_escolar,
      //  nu_id_nivel_educativo: this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel.nu_id_nivel_educativo
      //}
      console.log('params: ', params)
      this.$store.dispatch(LIST_PLAN_EVALUACION, params ).then(response => {
          this.list_plan_evaluacion = response.rest.items
          for (const item in response.rest.items) {
            console.log('Items: ', response.rest.items[item].criterio_evaluacions)
            this.list_criterios = response.rest.items[item].criterio_evaluacions
          }
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchAreas(params) {
      console.log('Año escolar: ', params)
      //var params = {
      //  nu_id_ano_escolar: q.nu_id_ano_escolar,
      //  nu_id_nivel_educativo: this.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel.nu_id_nivel_educativo
      //}
      console.log('searchAreas: ', params)
      this.$store.dispatch(LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREAS, params ).then(response => {
          this.list_institucion_nivel_grado_areas = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchAnoInstitucion(q) {
      this.isLoading = true
      var params = {
        nu_id_ano_escolar: q,
      }
      console.log('params: ', params)
      this.$store.dispatch(LIST_OFERTA_ACADEMICA, params ).then(response => {
            console.log('Items: ', response.rest.items)
          this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion = []
          for (const item in response.rest.items) {
            console.log('Items: ', response.rest.items[item])
              this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion.push({
                tx_nombre_ano: response.rest.items[item].tx_nombre_ano,
                nu_id_area_formacion: response.rest.items[item].nu_id_area_formacion,
                tx_nivel: response.rest.items[item].tx_nivel,
                tx_nombre_grado: response.rest.items[item].tx_nombre_grado,                
                tx_descripcion: response.rest.items[item].tx_descripcion,
                json_institucion: {
                  nu_id_institucion: response.rest.items[item].nu_id_institucion, 
                  tx_razon_social: response.rest.items[item].tx_razon_social,
                },
                json_nivel: {
                  nu_id_nivel_educativo: response.rest.items[item].nu_id_nivel_educativo, 
                  tx_nivel: response.rest.items[item].tx_nivel,
                },
                json_plan_estudio: {
                  nu_id_plan: response.rest.items[item].nu_id_plan, 
                  co_plan_estudio: response.rest.items[item].co_plan_estudio
                },
                json_grados: JSON.parse(response.rest.items[item].json_grados),
                json_areas: JSON.parse(response.rest.items[item].json_areas),


              }
              )        

          }

          //this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion = response.rest.items          
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      }).finally(() => (
        setTimeout(() => {
            this.isLoading = false
          },3000)               
        //this.e1 = 1
        //this.limpiar_campos_clientes()
        ))            
    },
    remove (item) {
      var index = this.institucion_especialidad.json_especialidad.findIndex(i => i.nu_id_especialidad === item.nu_id_especialidad);
      if (index >= 0) this.institucion_especialidad.json_especialidad.splice(index, 1)
    },
      
    async guardar () {
          console.log('Guardar: ')
          var isValid = false
          if (this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion) {
            isValid = true
          }
          if (isValid) {
            this.$emit("guardar_anoescolar_institucion", JSON.stringify(this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion));
          }          


        // const isValid = await this.$refs.datos_basicos_plan_institucion.validate()
        // if (!isValid) {
        //     return true
        // }
    },              
    async cancelar () {
    //this.loading4 = true 
        this.$emit("guardar_anoescolar_institucion", JSON.stringify(this.fields_oferta_academica));

    //this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          


  },
  
  mounted() {
    //this.searchPerfiles();
    //this.fields_oferta_academica.group_datos_basicos.tx_nombre_ano.tx_nombre_ano = this.send_datos.AnoEscolar.tx_nombre_ano
    //this.searchInstituciones()
    this.searchNiveles({
        nu_id_ano_escolar: this.currentUser.ano_escolar_activo.nu_id_ano_escolar, 
        nu_id_institucion: this.currentUser.login_institucion.nu_id_institucion
        }
    )
    //this.searchAnoInstitucion()
    //if (this.send_datos.operacion === 'EDITAR') {
        //this.fields_oferta_academica.group_datos_basicos.json_institucion.json_institucion = JSON.parse(this.send_datos.usuario.json_institucion)
        //this.fields_oferta_academica.group_datos_basicos.json_especialidad.json_especialidad = JSON.parse(this.send_datos.usuario.json_especialidad)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_oferta_academica[prop] === 'object') {
          //           if (this.fields_oferta_academica[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_oferta_academica[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_oferta_academica[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_oferta_academica[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    //} else {
        //this.limpiar()
        //this.list_plan_evaluacion = JSON.parse(this.fields_oferta_academica.group_datos_basicos.json_institucion.json_institucion[0].json_especialidad)
        //this.fields_oferta_academica.group_datos_basicos.json_ano_institucion.json_ano_institucion = []
    //    this.fields_oferta_academica.isEditing = true
    //}           
  },
    
}
</script>