 <template>
  <div class="w-full p-2">
      <div>
        <OfertaAcademicaHeader :titulo="titulo" /> 
      </div>  
      <div>
        <EvaluacionComp :send_datos="send_datos" @guardar_oferta_academica="return_oferta_academica"></EvaluacionComp>
      </div>

  </div>
</template>

<script>
import EvaluacionComp from "@/components/evaluacion/EvaluacionComp.vue";
import OfertaAcademicaHeader from "@/components/comunes/HeaderModulo.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { LIST_USUARIO } from '@/store/actions.type'

export default {
  name: 'OfertaAcademica',
  data () {
    return {
      titulo: 'Planes de evaluación',
      datos_usuario: [],
      send_usuario: {},

    }
  },
  props: {
      send_datos: Object,
  },  
  setup() {
  },  
  computed: {
  },
  components: {
    EvaluacionComp,
    OfertaAcademicaHeader
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    searchAnoEscolar(q) {
      this.$store.dispatch(LIST_USUARIO, {q: q}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_oferta_academica (msg) {
        //this.buscarPersona('params')

      this.$emit("guardar_oferta_academica", msg);

    },        

  },
  mounted () {
    //this.searchAnoEscolar({})
    /*  this.$store.dispatch(READ_USUARIO, {}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
