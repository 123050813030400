 <template>
  <div class="w-full p-2">

      <div>
        <PlanEvaluacionColegioOpciones @actualizar_datos_evaluacion="return_opciones" :send_datos="send_datos" :titulo="titulo"></PlanEvaluacionColegioOpciones>
      </div>

  </div>
</template>

<script>
import PlanEvaluacionColegioOpciones from "@/views/configuracion/Evaluacion/PlanEvaluacionColegio/PlanEvaluacionColegioOpciones.vue";

export default {
  name: 'Evaluacion',
  data () {
    return {
      titulo: 'Evaluación',
      datos_academia: [],
      send_datos: {
        list_instituciones: [],
        list_indicadores_logro: [],
        list_competencias: [],
        list_plan_evaluacion: []
      },

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    PlanEvaluacionColegioOpciones
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    return_opciones(msg) {
        console.log("return_opciiones: " ,msg);
      this.searchEvaluaciones({q: msg})

      //this.send_academia = msg;
    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_academia = msg;
        //this.select_academia = JSON.parse(msg);
        console.log("return_consulta_medica guardar_academia update: " , guardar_academia);

        
        if (guardar_academia.btn_guardar == 'true') {
            this.searchAcademia({})
            //this.fields.academia = this.select_academia.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_academia.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_academia.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_academia = msg;
    },        

  },
  mounted () {

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
