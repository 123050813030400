var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.mensaje.satisfactorio.status || _vm.mensaje.nosatisfactorio.status )?_c('div',{staticClass:"sticky top-0 z-50"},[_c('NotificacionInterna',{attrs:{"mensaje":_vm.mensaje}})],1):_vm._e(),_c('ValidationObserver',{ref:"datos_busqueda"},[_c('div',{staticClass:"md:flex md:justify-between"},[_c('div',{staticClass:"w-full pr-1"},[_c('validation-provider',{attrs:{"name":"fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel","rules":_vm.fields_oferta_academica.group_datos_basicos.json_nivel.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_niveles,"item-text":"tx_nivel","item-value":"nu_id_nivel_educativo","placeholder":_vm.fields_oferta_academica.group_datos_basicos.json_nivel.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_oferta_academica.group_datos_basicos.json_nivel.caracteristicas.label,"outlined":"","return-object":""},on:{"change":_vm.setGrados},model:{value:(_vm.fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel),callback:function ($$v) {_vm.$set(_vm.fields_oferta_academica.group_datos_basicos.json_nivel, "json_nivel", $$v)},expression:"fields_oferta_academica.group_datos_basicos.json_nivel.json_nivel"}})]}}])})],1),_c('div',{staticClass:"w-full pr-1"},[_c('validation-provider',{attrs:{"name":"fields_oferta_academica.group_datos_basicos.json_grados.json_grados","rules":_vm.fields_oferta_academica.group_datos_basicos.json_grados.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_institucion_nivel_grados,"item-text":"tx_nombre_grado","item-value":"nu_id_grado","placeholder":_vm.fields_oferta_academica.group_datos_basicos.json_grados.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_oferta_academica.group_datos_basicos.json_grados.caracteristicas.label,"outlined":"","return-object":""},on:{"change":_vm.setAreas},model:{value:(_vm.fields_oferta_academica.group_datos_basicos.json_grados.json_grados),callback:function ($$v) {_vm.$set(_vm.fields_oferta_academica.group_datos_basicos.json_grados, "json_grados", $$v)},expression:"fields_oferta_academica.group_datos_basicos.json_grados.json_grados"}})]}}])})],1),_c('div',{staticClass:"w-full pr-1"},[_c('validation-provider',{attrs:{"name":"fields_oferta_academica.group_datos_basicos.json_areas.json_areas","rules":_vm.fields_oferta_academica.group_datos_basicos.json_areas.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_institucion_nivel_grado_areas,"item-text":"tx_descripcion","item-value":"nu_id_area_formacion","placeholder":_vm.fields_oferta_academica.group_datos_basicos.json_areas.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_oferta_academica.group_datos_basicos.json_areas.caracteristicas.label,"outlined":"","return-object":""},on:{"change":_vm.setConfigurar},model:{value:(_vm.fields_oferta_academica.group_datos_basicos.json_areas.json_areas),callback:function ($$v) {_vm.$set(_vm.fields_oferta_academica.group_datos_basicos.json_areas, "json_areas", $$v)},expression:"fields_oferta_academica.group_datos_basicos.json_areas.json_areas"}})]}}])})],1)])]),_c('div',{staticClass:"pl-2 pr-2"},[_c('div',{staticClass:"pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black"},[_c('v-text-field',{attrs:{"clearable":"","prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Buscador"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"rounded-lg"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list_criterios,"items-per-page":5,"search":_vm.search,"expanded":_vm.expanded,"single-expand":_vm.singleExpand,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.indicadores_logro(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiBookPlusMultiple)+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.agregar_actividad(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlarmPlus)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('header',{staticClass:"grid grid-cols-3 gap-4 bg-gray-300"},[_c('div',{staticClass:"..."},[_vm._v("Indicador")]),_c('div',{staticClass:"..."},[_vm._v("Ponderación")])]),_vm._l((item.criterio_indicadores_aprendizajes),function(item_01,index){return _c('div',{key:index,staticClass:"grid grid-cols-3 gap-4"},[_c('div',{staticClass:"..."},[_vm._v(" "+_vm._s(item_01.tx_denominacion_indicador_aprendizaje))]),_c('div',{staticClass:"..."},[_vm._v(" "+_vm._s(item_01.nu_ponderacion_indicador_aprendizaje))])])})],2)]}}])})],1)]),(_vm.dialogDefinirUnidades)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","value":true},on:{"input":function($event){_vm.dialogDefinirUnidades = false}}},[_c('v-card',[_c('v-container',[_c('div',{staticClass:"w-full mx-auto my-auto"},[_c('Actividades',{attrs:{"titulo":_vm.titulo,"sub_titulo":_vm.sub_titulo,"send_datos":_vm.send_datos},on:{"guardar_definir_unidad":_vm.return_definir_unidad}})],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }