 <template>
  <div class="w-full p-2">
      <div>
        <ActividadOpciones :titulo="titulo" :sub_titulo="sub_titulo" :send_datos="send_datos" @guardar_definir_unidad="return_definir_unidad"></ActividadOpciones>
      </div>

  </div>
</template>

<script>
import ActividadOpciones from "@/views/configuracion/Academica/Actividad/ActividadOpciones.vue";


export default {
  name: 'Actividad',
  data () {
    return {
      datos_academia: [],
    }
  },
  setup() {
  },  
  computed: {
  },
  props: {
      send_datos: Object,
      titulo: String,
      sub_titulo: String
  },  
  components: {
    ActividadOpciones
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    return_definir_unidad (msg) {
        //this.buscarPersona('params')

      this.$emit("guardar_definir_unidad", msg);

    },        

  },
  mounted () {

  },

}
</script>
